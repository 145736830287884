import React, {useEffect ,useRef, useCallback} from 'react';
import '../style/about.css';
import h from '../assets/profile.jpeg';
import profile from '../assets/profile_about.jpeg';
import {useTitle} from "../hooks/useTitle";
// import Contact from "../components/contact";
// import {useCallback, useEffect, useRef} from "@types/react";

function About(props) {
    useTitle('About');
    // Function will execute on click of button
    const mainContentRef = useRef(null);
    const scrollIntoView = useCallback(() => {
        mainContentRef.current.scrollIntoView();
    }, []);
    useEffect(() => {
        scrollIntoView();
    }, [scrollIntoView]);

    const onButtonClick = () => {
        // using JavaScript method to get PDF file
        fetch('Resume.pdf').then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'farjana_chadni_resume.PDF';
                alink.click();
            })
        })
    }

    return (
        <main className="about">
            <div className="about-intro" ref={mainContentRef}>
                <div className="about-subheading">
                    <p>My name is Farjana Chadni and I'm currently in my last year at
                        Michigan State University, I'm studying Computer Science, Experience Architecture(UX), and
                        Information Science (Human-Centered Technologies).
                        <br/><br/>
                        {/*<p>Conducting a secondary research on application of AI in various contexts, with emphasis on its*/}
                        {/*    impact on K-12 education, and analyzing its potential benefits and limitations.</p>*/}
                        {/*<br/><br/>*/}
                        Currently I am working on developing an 3D open-world exploratory educational video game that is
                        centered around the periodic table of elements; which aims to make learning chemistry fun and
                        engaging for elementary to high school students.
                        <br/><br/>
                        I am also working on developing a cross-platform AI-integrated Islamic mobile app for the novice
                        internet users, individual with limited digital literacy who experience difficulties using
                        technologies.
                    </p>
                    <button className="download-resume" onClick={onButtonClick}>
                            Download Resume PDF
                    </button>
                </div>
                <div className="hero-img"><img src={profile} alt=""/></div>
            </div>
            {/*<Contact/>*/}
            {/*<div className="about-resume">*/}
            {/*    <h2 className="resume-title">FARJANA CHADNI</h2>*/}
            {/*    <div className="education">*/}
            {/*        <p className="resume-header">EDUCATION</p>*/}
            {/*        <div className="header-and-date">*/}
            {/*            <p>Undergraduate Student | Michigan State University</p>*/}
            {/*            <p>Aug 2018 – May 2023</p>*/}
            {/*        </div>*/}
            {/*        <ul className="bullets">*/}
            {/*            <li>Bachelor of Science, Computer Science</li>*/}
            {/*            <li>Bachelor of Arts, Experience Architecture (UX)</li>*/}
            {/*            <li>Bachelor of Arts, Information Science (human-Centered Technologies)</li>*/}
            {/*        </ul>*/}
            {/*    </div>*/}
            {/*    <div className="education">*/}
            {/*        <p className="resume-header" >EXPERIENCE</p>*/}
            {/*        <div className="resume-gap">*/}
            {/*            <div className="header-and-date">*/}
            {/*                <p>User Experience Researcher</p>*/}
            {/*                <p>Sep 2022 – Present</p>*/}
            {/*            </div>*/}
            {/*            <p className="location">Michigan User Experience Professionals Association (MiUXPA) | Michigan</p>*/}
            {/*            <ul className="bullets">*/}
            {/*                <li>Collaborating on a research team to conduct data on Michigan-based companies' implications*/}
            {/*                    of UX to provide young professionals with an overview of UX in Michigan.</li>*/}
            {/*                <li>Conducting secondary research on users who are being introduced to technology for the first*/}
            {/*                    time or those who lack digital confidence to create a knowledge base for UX professionals.</li>*/}
            {/*            </ul>*/}
            {/*        </div>*/}
            {/*        <div className="resume-gap">*/}
            {/*            <div className="header-and-date">*/}
            {/*                <p>User Experience Designer & Engineer </p>*/}
            {/*                <p> Jan 2022 – Present</p>*/}
            {/*            </div>*/}
            {/*            <p className="location">The Cube | Michigan State University</p>*/}
            {/*            <ul className="bullets">*/}
            {/*                <li>Collaborated on a cross-functional team of 10+ members to research & influence*/}
            {/*                    accessibility for a nonprofit organization (DAP) that acts as a resource on venue accessibility in Detroit.</li>*/}
            {/*                <li>Developed the initial build of the DAP using reactJs and REST API; built the final*/}
            {/*                    version of the website using WordPress integrated with elementor pro and Crocoblock plugins.</li>*/}
            {/*            </ul>*/}
            {/*        </div>*/}
            {/*        <div className="resume-gap">*/}
            {/*            <div className="header-and-date">*/}
            {/*                <p>Web Application Developer</p>*/}
            {/*                <p>May 2022 – Aug 2022</p>*/}
            {/*            </div>*/}
            {/*            <p className="location">Open Systems Technologies | Grand Rapids, MI</p>*/}
            {/*            <ul className="bullets">*/}
            {/*                <li>Contributed to the development of space planning software using agile methodology for*/}
            {/*                    Miller Knoll using angular and ASW amplify; accelerated time to create and design spaces using technology.</li>*/}
            {/*                <li>Gained experience in agile methodology by collaborating with a senior developer, QA*/}
            {/*                    engineer, and project manager while participating in sprints discussion on daily standup sessions.</li>*/}
            {/*            </ul>*/}
            {/*        </div>*/}
            {/*    </div>*/}

            {/*    <div className="education">*/}
            {/*        <p className="resume-header" >PROJECT</p>*/}
            {/*        <div className="resume-gap">*/}
            {/*            <div className="header-and-date">*/}
            {/*                <p>Mobile App | React Native | Independent Project</p>*/}
            {/*                <p>July 2022 - Present</p>*/}
            {/*            </div>*/}
            {/*            <ul className="bullets">*/}
            {/*                <li>Researched digital literacy for developing an Islamic mobile app; conducted 20 user*/}
            {/*                    surveys and 5 interviews to gain an understanding of novice internet users; compared*/}
            {/*                    and contrasted existing Islamic apps;  created wireframes, information architecture,*/}
            {/*                    and prototypes with digital literacy in mind. Currently programming AI-integrated mobile*/}
            {/*                    app using react native.</li>*/}
            {/*            </ul>*/}
            {/*        </div>*/}
            {/*        <div className="resume-gap">*/}
            {/*            <div className="header-and-date">*/}
            {/*                <p>Apple IOS Design Lab | Michigan State University</p>*/}
            {/*                <p>Sep 2022 - Present</p>*/}
            {/*            </div>*/}
            {/*            <ul className="bullets">*/}
            {/*                <li>In partnership with Apple, collaborating with a diverse group of 20+ members to*/}
            {/*                    empathize, define, ideate, prototype, test; review, research and develop IOS*/}
            {/*                    applications in innovative space by implying entrepreneurship principles and project*/}
            {/*                    management techniques to solve real-world problems.*/}
            {/*                </li>*/}

            {/*            </ul>*/}
            {/*        </div>*/}
            {/*        <div className="resume-gap">*/}
            {/*            <div className="header-and-date">*/}
            {/*                <p> NASA L'SPACE Mission Concept Academy | NASA </p>*/}
            {/*                <p> Aug 2021- Dec 2021 </p>*/}
            {/*            </div>*/}
            {/*            <ul className="bullets">*/}
            {/*                <li>Characterized water ice sites on Mars: Implications for Astrobiology and Human*/}
            {/*                    Exploration; In a team of 10 students, we researched and designed a rover with drilling*/}
            {/*                    capability to discover and create a habitable environment on Mars for human exploration.*/}
            {/*                    As part of, the science and engineering research team, we met twice a week to collaborate*/}
            {/*                    on our findings. </li>*/}
            {/*            </ul>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="education">*/}
            {/*        <p className="resume-header" >EXPERTISE</p>*/}
            {/*        <ul className="bullets">*/}
            {/*            <li>UX Design: Human Centered Design, Prototyping,  Information Architecture, and Storyboarding.</li>*/}
            {/*            <li>UX Research: Interview, User Survey, Usability testing, User Journey, User Accessibility.</li>*/}
            {/*            <li>Programming: JavaScript, ReactJs, React Native, Angular,  HTML/CSS,  Python, Swift, and C++.</li>*/}
            {/*        </ul>*/}
            {/*        <div className="about-skills">*/}
            {/*            <div>*/}
            {/*                <div className="about-skill-icon">*/}
            {/*                    <img src={javascript} alt="javascript"/>*/}
            {/*                    <p>JavaScript</p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div>*/}
            {/*                <div className="about-skill-icon">*/}
            {/*                    <img src={react} alt="react" className="react"/>*/}
            {/*                    <p>ReactJs</p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div>*/}
            {/*                <div className="about-skill-icon">*/}
            {/*                    <img src={angular} alt="angular"/>*/}
            {/*                    <p>Angular</p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div>*/}
            {/*                <div className="about-skill-icon">*/}
            {/*                    <img src={python} alt="python"/>*/}
            {/*                    <p>Python</p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div>*/}
            {/*                <div className="about-skill-icon">*/}
            {/*                    <img src={cpp} alt="cpp"/>*/}
            {/*                    <p>C++</p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div>*/}
            {/*                <div className="about-skill-icon">*/}
            {/*                    <img src={swift} alt="swift"/>*/}
            {/*                    <p>Swift</p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div>*/}
            {/*                <div className="about-skill-icon">*/}
            {/*                    <img src={figma} alt=""/>*/}
            {/*                    <p>Figma</p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div>*/}
            {/*                <div className="about-skill-icon">*/}
            {/*                    <img src={xd} alt=""/>*/}
            {/*                    <p>Adobe XD</p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div>*/}
            {/*                <div className="about-skill-icon">*/}
            {/*                    <img src={illustrator} alt=""/>*/}
            {/*                    <p>Illustrator</p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div>*/}
            {/*                <div className="about-skill-icon">*/}
            {/*                    <img src={procreate} alt=""/>*/}
            {/*                    <p>Procreate</p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div>*/}
            {/*                <div className="about-skill-icon">*/}
            {/*                    <img src={github} alt=""/>*/}
            {/*                    <p>Github</p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div>*/}
            {/*                <div className="about-skill-icon">*/}
            {/*                    <img src={gitlab} alt=""/>*/}
            {/*                    <p>Gitlab</p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </main>
    );
}

export default About;