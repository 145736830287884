import React from "react";
import './style/App.css';
import ReactGA from "react-ga";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import About from "./pages/about";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import Deen from "./components/deen";
import Dap from "./components/dap";
import {Helmet} from "react-helmet";
import AlQamar from "./components/al-qamar";
import InteractiveDesign from "./components/interactive_design";

const TrackingId = "349466971";
ReactGA.initialize(TrackingId)

function App() {
  return (
    <Router>
      <div className="App">
        <Helmet>
          <meta charSet="utf-8" />
          <title> Farjana Chadni </title>
          <meta name="description" content="this is my portfolio"/>
        </Helmet>
        <div>
          <Navbar/>
        </div>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/about" element={<About/>}/>
          <Route path="/deen" element={<Deen/>}/>
          <Route path="/al-qamar" element={<AlQamar/>}/>
          <Route path="/interactive-design" element={<InteractiveDesign/>}/>
          <Route path="/dap" element={<Dap/>}/>
        </Routes>
        <div>
          <Footer/>
        </div>
      </div>
    </Router>
  );
}

export default App;
