import React, { useState} from 'react';
import { Link } from "react-router-dom";
import "../style/navbar.css";
import { FaBars } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';
import logo from "../assets/logo icon.png";


function Navbar(props) {
    const [navbar, setNavbar] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const [activeItem, setActiveItem] = useState("Work");
    const handleClick = (item) => {
        setActiveItem(item);
    };
    const navbarEffect = () => {
      if(window.scrollY >= 50){
          setNavbar(true);
      }else{
          setNavbar(false);
      }
    }

    window.addEventListener('scroll', navbarEffect);

    const onButtonClick = () => {
        // using JavaScript method to get PDF file
        fetch('Resume.pdf').then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'farjana_chadni_resume.PDF';
                alink.click();
            })
        })
    }

    return (
        <>
            {/*<nav ref={navRef}>*/}
            <nav className={"navbar"}>
                <div className={ navbar ? "navbar-container active" : "navbar-container"}>
                    <div className="logo">
                        <Link to="/"><span className="logo-text"><img src={logo} alt=""/></span></Link>
                    </div>

                    <button className='mobile-menu-icon' onClick={ () => setIsMobile(!isMobile)}>
                        { isMobile ? (
                            <i className='<FaBars/>'><AiOutlineClose/></i>
                        ):(
                            <i className='<AiFillLinkedin/>'><FaBars/></i>
                        )}
                    </button>

                    <ul className={isMobile ? "navbar-menu-mobile" : "navbar-menu"} onClick={() => setIsMobile(false)}>
                        <li className="navbar-item">
                            <Link to="/"><span
                                className={activeItem === "Work" ? "navbar-link active" : "navbar-link"}
                                onClick={() => handleClick("Work")}>Work</span></Link>
                        </li>
                        <li className="navbar-item">
                            <Link to="/about"><span
                                className={activeItem === "About" ? "navbar-link active" : "navbar-link"}
                                onClick={() => handleClick("About")}>About</span></Link>
                        </li>
                        <li className="navbar-item">
                            <span className="navbar-link" onClick={onButtonClick}>Resume</span>
                        </li>
                    </ul>

                </div>
            </nav>
        </>
    );
}

export default Navbar;