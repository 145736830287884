import React, {useEffect ,useRef, useCallback} from 'react';
import "../style/projects.css";
import comparativeResearch from "../assets/case_study/IslamicApp/compare.png";
import vanDiagram from "../assets/case_study/IslamicApp/van diagram.jpg";
import focusArea from "../assets/case_study/IslamicApp/focusArea.jpg";
import wireframe from "../assets/case_study/IslamicApp/wireframe.png";
import informationArchitecture from "../assets/case_study/IslamicApp/infoart.jpg";
import userTesting1 from "../assets/case_study/IslamicApp/user testing.jpg";
import differences from "../assets/case_study/IslamicApp/how different.jpg";
import banner from "../assets/case_study/IslamicApp/deen banner.png";
import featureIdeas from "../assets/case_study/IslamicApp/featureIdeas.jpg";
import homeWireframe from "../assets/case_study/IslamicApp/home screen wireframe.png";
import chatWireframe from "../assets/case_study/IslamicApp/chat screen wireframe.png";
import featuresWireframe from "../assets/case_study/IslamicApp/features screen wireframe.png";
import {useTitle} from "../hooks/useTitle";
import TableOfContents from "./TableOfContents";
import AlQamarBanner from "../assets/case_study/Restaurant/rest banner.gif";
import dapBanner from "../assets/case_study/dap banner.png";


const projects = [
    {
        title: 'DAP website',
        description: 'An responsive website that provides comprehensive venue accessibility information in Detroit.',
        banner: dapBanner,
        link: '/dap',
        button: 'View Case Study'
    },
    {
        title: 'Restaurant App',
        description: 'A mobile app that enhances the customer experience for Al-Qamar fast food restaurant.',
        banner: AlQamarBanner,
        link: '/al-qamar',
        button: 'View Case Study'
    },
];


function Deen(props) {
    useTitle('Islamic App');

    const mainContentRef = useRef(null);
    const scrollIntoView = useCallback(() => {
        mainContentRef.current.scrollIntoView();
    }, []);
    useEffect(() => {
        scrollIntoView();
    }, [scrollIntoView]);

    return (
        <main>
            <div className="case-study-banner deen" ref={mainContentRef}>
                <div className="case-study-left">
                    <h1 className="case-study-title">AI-integrated Islamic app</h1>
                    <p className="case-study-brief">An mobile app for the novice internet users, individual with limited
                        digital literacy who experience difficulties using technologies.</p>
                </div>
                <div className="case-study-right">
                    <img src={banner} alt="phone screens" loading="lazy"/>
                </div>
            </div>
            <div className="project-container">
                {/*<div className="side-bar">*/}
                {/*    <TableOfContents />*/}
                {/*</div>*/}
                <div className="project-content">
                    <section className="project-overview" id="projectOverview">
                        <div className="three-rows">
                            <div>
                                <h3 className="project-subheading">My Role</h3>
                                <p className="project-des">Solo UX Design Engineer</p>
                            </div>
                            <div>
                                <h3 className="project-subheading">Tools</h3>
                                <p className="project-des">Figma & React Native</p>
                            </div>
                            <div>
                                <h3 className="project-subheading">Project Duration</h3>
                                <p className="project-des">June 2022 - Present</p>
                            </div>
                        </div>
                        <h3 className="project-subheading">Overview</h3>
                        <p className="project-des">As a Muslim and the daughter of a novice internet user, I have recognized the
                            gap in the market for an app that addresses the needs of Muslims like my mother, who have limited
                            digital literacy and experience difficulties using technologies. That's how the idea of my Islamic
                            mobile app was born. The app is integrated with artificial intelligence to assist users to practice
                            their faith with ease.</p>
                        <h2 className="project-subheading">Problem</h2>
                        <p className="project-des">
                            Existing Islamic apps are not tailored to novice internet users, who have limited digital literacy,
                            which makes it difficult for these users to access digital information.
                        </p>
                        <h2 className="project-subheading">Goal</h2>
                        <p className="project-des">
                            The goal is to develop an artificial intelligence integrated Islamic mobile app that is tailored
                            to the needs of novice internet users, making it easy to access religious information.
                        </p>
                        <h2 className="project-subheading">Features & Technologies</h2>
                        <p className="project-des">
                            <ul>
                                <li> <strong>Onboarding process:</strong> A guided process to help new users set up and use the app.</li>
                                <li><strong>Virtual assistant:</strong> An AI-powered assistant to help users navigate the app and learn about Islam.</li>
                                {/*<li><strong>Virtual chatbot:</strong> An AI-powered chatbot to answer users' questions about Islam.</li>*/}
                                {/*<li><strong>Voice command support:</strong> Allowing users to control the app with voice commands.</li>*/}
                                <li><strong>Multi-language support:</strong> Supporting multiple languages for users from different backgrounds.</li>
                                {/*<li><strong>Natural language processing:</strong> Understanding and responding to user input in natural language.</li>*/}
                                <li><strong>Prayer time:</strong> Showing accurate prayer times for the user's location.</li>
                                <li><strong>Qibla direction:</strong> Providing the direction of Qibla for the user's location.</li>
                                <li><strong>Salah guide:</strong>  Offering a guide to help the user perform their prayers correctly.</li>
                            </ul>
                        </p>
                    </section>

                    <br/>
                    <hr/>

                    <section className="project-research" id="understandingUser">
                        <h2 className="project-subheading">Understanding The Current User</h2>
                        <p className="project-des">I was able to conduct a total of 20 insights of existing Islamic app
                            users; 5 user interviews and 15 user surveys. Some of my findings are represented below:</p>
                        <ul className="project-des">
                            <li>Many users felt that the apps were useful and convenient for tracking their daily prayers
                                and staying connected to their faith.</li>
                            <li>Some users reported that the apps were difficult to navigate or lacked certain features
                                they wanted.</li>
                            <li>A few users mentioned that they were frustrated by the presence of ads in the free
                                versions of the apps.</li>
                        </ul>
                        <div className="image01"><img src={userTesting1} alt="Survey and interview findings" loading="lazy"/></div>
                        <p className="project-des">These insights helped me to understand the strengths and weaknesses of
                            existing apps, and identify opportunities for improvement in my own app.</p>

                        {/*<h2 className="project-subheading">Understanding the Novice Internet Users</h2>*/}
                        {/*<p className="project-des">Once I had a better understanding of the needs and preferences of existing*/}
                        {/*    Islamic app users, I turned my attention to my target audience: users with low digital confidence.*/}
                        {/*    For this phase of the research, I focused on analyzing online research and articles, with a particular*/}
                        {/*    emphasis on digital literacy. I studied Google's research on the next billion users, as well as other*/}
                        {/*    online sources, to gain insights into the challenges and needs of this target audience.</p>*/}
                        <h2 className="project-subheading">Who are the novice internet users</h2>
                        <p className="project-des">Novice internet users are the people around the world who experience
                            low digital confidence usually due to low digital literacy. I am sure you have encountered a
                            novice user before if it is an elderly relative asking your assistance on resetting their iPad.
                            It is predicted that by 2025, a billion more people will start using a smartphone for the first time.
                            These novice users will want to use their devices to their full potential, for purposes such as accessing
                            information, entertainment, empowerment, convenience, and connecting with global community.</p>
                        <h2 className="project-subheading">How are those users different</h2>
                        <div className="image01"><img src={differences} alt="similarities among the users" loading="lazy"/></div>

                        <h2 className="project-subheading">Comparative Analysis</h2>
                        <p className="project-des">I conducted a comparative analysis of existing Islamic apps (Muslim Pro,
                            Muslim Mate, and Athan) in order to identify their best and unique features.</p>
                        <div className="image01"><img src={comparativeResearch} alt="Comparative Analysis" loading="lazy"/></div>
                        <p className="project-des">I created a Venn diagram to visualize the similarities and differences
                            between the apps and used this information to identify most used features.</p>
                        <div className="image01"><img src={vanDiagram} alt="van diagram of the comparative analysis" loading="lazy"/></div>

                        <h2 className="project-subheading">50 Feature Idea Wall</h2>
                        {/*<p className="project-des">After conducting a comparative analysis of existing apps, I spent time*/}
                        {/*    generating potential features for the app. I also studied user reviews of these apps to understand*/}
                        {/*    the features and functionality that users value and want to see. This research allowed me to create*/}
                        {/*    a list of potential features.</p>*/}
                        <div className="image01"><img src={featureIdeas} alt="sticky notes of 50 features ideas" loading="lazy"/></div>

                        <h2 className="project-subheading">Key Features</h2>
                        <p className="project-des">After conducting thorough research and brainstorming 50 different ideas,
                            I have narrowed down the features to serve my target users. The features that I have chosen for
                            the app are: Prayer time, Qibla direction, Salah guide, Virtual assistant, and Multi-language
                            support. By incorporating the features that users want and need, I aim to create an app that
                            is useful, convenient, and user-friendly.</p>
                    </section>

                    <br/>
                    <hr/>

                    <section className="project-sketch" id="startingDesign">
                        <h2 className="project-subheading">Focus Area for This case study</h2>
                        <p className="project-des">Based on my understanding and analysis of both primary and secondary
                            research. I have identified several areas for improvement that will make this app more accessible
                            and user-friendly for the users. These focus areas include: Onboarding, Navigation, Language,
                            Virtual Assistance, and visuals.</p>
                        <div className="image01"><img src={focusArea} alt="focus area diagram" loading="lazy"/></div>

                        <h2 className="project-subheading">Sketch</h2>
                        {/*<p className="project-des">Below I finalized a sketch for the home screen of the app. The app features*/}
                        {/*    listed are based on the most commonly used features of existing prayer apps. My goal is to make*/}
                        {/*    these features easily accessible to users, so I decided to display them in a grid format on the*/}
                        {/*    home screen. This will allow users to quickly and easily access the information.</p>*/}
                        <div className="image01"><img src={wireframe} alt="wireframe" loading="lazy"/></div>

                        <h2 className="project-subheading">Information Architecture</h2>
                        <p className="project-des">Now that I have a clear vision of the features of the app. I have decided
                            to use a linear navigation structure to make it easier for users to follow and understand. This
                            is because tree structure navigation can be challenging for novice users, so I have avoided this
                            complexity in order to create a user-friendly app. </p>
                        <div className="image01"><img src={informationArchitecture}  alt="Information Architecture diagram" loading="lazy"/></div>

                        <h2 className="project-subheading">Home Screen Wireframe</h2>
                        <div className="image01"><img src={homeWireframe}  alt="Home Screen Wireframe" loading="lazy"/></div>
                        <h2 className="project-subheading">Chatbot Screen Wireframe</h2>
                        <div className="image01"><img src={chatWireframe}  alt="Chatbot Screen Wireframe" loading="lazy"/></div>
                        <h2 className="project-subheading">Feature Screen Wireframes</h2>
                        <div className="image01"><img src={featuresWireframe}  alt="Feature Screen Wireframes" loading="lazy"/></div>
                    </section>
                    {/*<section className="project-refine" id="refiningDesign">*/}
                    {/*    <h1 className="project-heading">Refining The Design</h1>*/}
                    {/*    <h2 className="project-subheading" id="midFiPrototype">Mid-Fi Prototype</h2>*/}
                    {/*    <p className="project-des">I have created a mid-fidelity prototype of the app, which allows*/}
                    {/*        me to visualize and interact with the design. I am currently using online resources as*/}
                    {/*        placeholder graphics and images, but I plan to create my own icons, illustrations, and other*/}
                    {/*        graphics once the design is finalized. Once these elements are complete, I will create*/}
                    {/*        a high-fidelity prototype of the app. This process is ongoing, and I expect to have a*/}
                    {/*        completed high-fidelity prototype by early 2023.</p>*/}
                    {/*    <div className="containerDeen">*/}
                    {/*        <div className="image-container">*/}
                    {/*            <img src={onboardingGif}  alt="on boarding gif" loading="lazy" className="image25"/>*/}
                    {/*        </div>*/}
                    {/*        <div className="image-container">*/}
                    {/*            <img src={quranScreenGif}  alt="quran screen gif" loading="lazy" className="image25"/>*/}
                    {/*        </div>*/}
                    {/*        <div className="image-container">*/}
                    {/*            <img src={salahScreenGif}  alt="salah screen gif" loading="lazy" className="image25"/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*    <h2 className="project-subheading" id="onboarding">On-boarding</h2>*/}
                    {/*    <p className="project-des">One of my focus areas for the app is on-boarding, and I have included*/}
                    {/*        several features to make this process smooth and user-friendly. I have added a virtual*/}
                    {/*        assistant who will guide users through the on-boarding process, providing step-by-step*/}
                    {/*        instructions and assistance. <br/><br/>*/}
                    {/*        I have also included a language selection option at the beginning of the on-boarding process,*/}
                    {/*        so that users can choose to receive instructions in their native language. This is especially*/}
                    {/*        important for tasks such as enabling location and notifications, which may be confusing for*/}
                    {/*        users with low digital literacy. <br/><br/>*/}
                    {/*        Additionally, I have chosen not to include a sign-up option, as most of my target*/}
                    {/*        users may not understand what an account is or how to create one. By providing clear and*/}
                    {/*        simple instructions and support, I aim to make the on-boarding process as easy and stress-free*/}
                    {/*        as possible for users.</p>*/}
                    {/*    <div className="containerDeen">*/}
                    {/*        <div className="image-container border">*/}
                    {/*            <img src={onboarding1}  alt="on boarding screen 1" loading="lazy" className="image25"/>*/}
                    {/*        </div>*/}
                    {/*        <div className="image-container border">*/}
                    {/*            <img src={onboarding2}  alt="on boarding screen 2" loading="lazy" className="image25"/>*/}
                    {/*        </div>*/}
                    {/*        <div className="image-container border">*/}
                    {/*            <img src={onboarding3}  alt="on boarding screen 3" loading="lazy" className="image25"/>*/}
                    {/*        </div>*/}
                    {/*        <div className="image-container border">*/}
                    {/*            <img src={onboarding4}  alt="on boarding screen 4" loading="lazy" className="image25"/>*/}
                    {/*        </div>*/}
                    {/*        <div className="image-container border">*/}
                    {/*            <img src={onboarding5}  alt="on boarding screen 5" loading="lazy" className="image25"/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*    <h2 className="project-subheading">Home Screen</h2>*/}
                    {/*    <p className="project-des">(again, the icons are used here are placeholder and will be replaced for Hi-Fi prototype)</p>*/}
                    {/*    <div className="containerDeen">*/}
                    {/*        <div className="image-container">*/}
                    {/*            <img src={focusAreaHome}  alt="focus area for home screen" loading="lazy" className="image25"/>*/}
                    {/*        </div>*/}
                    {/*        <div className="image-container">*/}
                    {/*            <img src={homeScreenGif}  alt="home screen gif" loading="lazy" className="image25"/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*    <h2 className="project-subheading">Mid-Fi Video Walk through</h2>*/}
                    {/*    <div className="video-walk">*/}
                    {/*        <video controls>*/}
                    {/*            <source src={midFiVideo}/>*/}
                    {/*        </video>*/}
                    {/*    </div>*/}
                    {/*</section>*/}

                    <br/>
                    <hr/>

                    <section className="project-next" id="goingForward">
                        {/*<h2 className="project-subheading">Takeaways</h2>*/}
                        <h2 className="project-subheading">Next steps</h2>
                        <ul className="project-des">
                            <li><strong>Usability Testing:</strong> Usability testing is in progress, will be reported once completed.</li>
                            <li><strong>Hi-Fi Prototype:</strong> Once the usability testing is complete, results will be used to refine the design.</li>
                            <li><strong>Development:</strong> App will be built in react native for cross platforms. The goal is
                                to get the Minimum Viable Product launched after hi-fi prototype completion.</li>
                        </ul>
                        <h2 className="project-subheading">Let’s connect!</h2>
                        <p className="project-des">I am not an expert, my goal is to make technology
                            accessible and inclusive for everyone worldwide. If you have any feedback and constructive criticism,
                            please send me a message, email or connect via Linkedin; any and all feedback and criticism will be
                            highly appreciated and aid in improving the app. <br/><br/> Thank you for taking the time to read
                            the case study.</p>
                    </section>

                    <section>
                        <br/><br/><br/>
                        <h1 className="project-heading">Other Projects</h1>
                        <div>
                            <div className="projects-min">
                                {projects.map(project => (
                                    <div className="project">
                                        <img className="banner-image project-deen" src={project.banner} key={project.title} alt=""/>
                                        <div className="project-info">
                                            <p className="project-title">{project.title}</p>
                                            <p className="project-brief">{project.description}</p>
                                            <a href={project.link}>
                                            <span className="project-link">
                                              <span className="button-text">{project.button}</span>
                                              {/*<span className="button-icon"><IoIosArrowRoundForward/></span>*/}
                                            </span>
                                            </a>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </main>
    );
}

export default Deen;