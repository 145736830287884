import React from 'react';
import DeenBanner from '../assets/case_study/IslamicApp/deen banner.gif';
import AlQamarBanner from '../assets/case_study/Restaurant/rest banner.gif';
import dapBanner from "../assets/case_study/dap banner.png";
import designBanner from "../assets/case_study/GD468/interaction_design_banner.png";
import ccpBanner from "../assets/case_study/ccp banner.png"
import '../style/home.css';

const projects = [
    {
        title: 'DAP website',
        description: 'An responsive website that provides comprehensive venue accessibility information in Detroit.',
        banner: dapBanner,
        link: '/dap',
        button: 'View Case Study'
    },
    {
        title: 'Community Composing Project',
        description: 'An website that showcases written and oral stories of the Mid-Michigan community.            ',
        banner: ccpBanner,
        link: '/',
        button: 'In Progress'
    },
    {
        title: 'Deen',
        description: 'A Islamic mobile app for the novice internet users, individual with limited digital literacy.',
        banner: DeenBanner,
        link: '/deen',
        button: 'View Case Study'
    },
    {
        title: 'Restaurant App',
        description: 'A mobile app that enhances the customer experience for Al-Qamar fast food restaurant.        ',
        banner: AlQamarBanner,
        link: '/al-qamar',
        button: 'View Case Study'
    },
    {
        title: 'Reducing Food Waste',
        description: 'Interactive experience for the SOS lab to teach middle school students about the food waste.',
        banner: designBanner,
        link: '/interactive-design',
        button: 'View Case Study'
    },
];

function Work(props) {
    return (
        <div>
            <div className="projects">
                {
                  projects.map(project => (
                      <div className="project">
                          <img className="banner-image project-deen" src={project.banner} key={project.title} alt=""/>
                          <div className="project-info">
                              <p className="project-title">{project.title}</p>
                              <p className="project-brief">{project.description}</p>
                              <a href={project.link}>
                                  <span className="project-link">
                                      <span className="button-text">{project.button}</span>
                                  </span>
                              </a>
                          </div>
                      </div>
                  ))}
            </div>
        </div>
    );
}

export default Work;