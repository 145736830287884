import React, {useCallback, useEffect, useRef} from 'react';
import '../style/home.css'
import Intro from "../components/intro";
import Work from "../components/work";
import {useTitle} from "../hooks/useTitle";
import arrow from "../assets/arrow.svg";
// import {useCallback, useEffect, useRef} from "@types/react";



function Home(props) {
    useTitle('Farjana Chadni');

    const mainContentRef = useRef(null);
    const scrollIntoView = useCallback(() => {
        mainContentRef.current.scrollIntoView();
    }, []);
    useEffect(() => {
        scrollIntoView();
    }, [scrollIntoView]);

    return (
        <div className="home-container"  ref={mainContentRef}>
            <section>
                <Intro/>
            </section>
            <section>
                <div className="to-casestudy">
                    <p>See Case Studies</p>
                    <img src={arrow} alt="arrow point down"/>
                </div>
            </section>
            <section>
                <Work/>
            </section>
        </div>
    );
}

export default Home;