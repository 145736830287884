import React from 'react';
import '../style/home.css';
import javascript from "../assets/logo/javascript-logo-transparent-logo-javascript-images-3.png";
import react from "../assets/logo/react.png";
import python from "../assets/logo/python-logo.png";
import swift from "../assets/logo/swift-logo.png";
import figma from "../assets/logo/figma.png";
import github from "../assets/logo/github-logo.png";
import agile from "../assets/logo/scrum.png";
import h from "../assets/profile.jpeg";
import profile from "../assets/profile_pic.png";

function Intro(props) {

    return (
        <div>
            <div className="hero">
                <div>
                    <div className="hero-left">
                        <h1 className="homeheader">
                            Hey, I'm Farjana!
                            <br/>
                            I research, design, code</h1>
                        <p className="direction">(See Skills)</p>
                        <div className="languages">
                            <div>
                                <li className="skill-icon">
                                    <img src={python} alt="python"/>
                                    {/*<p>Python</p>*/}
                                </li>
                            </div>
                            <div>
                                <li className="skill-icon">
                                    <img src={javascript} alt="javascript"/>
                                    {/*<p>JavaScript</p>*/}
                                </li>
                            </div>
                            <div>
                                <li className="skill-icon react">
                                    <img src={react} alt="react" className="react"/>
                                    {/*<p>ReactJs</p>*/}
                                </li>
                            </div>
                            <div>
                                <li className="skill-icon">
                                    <img src={swift} alt="swift"/>
                                    {/*<p>Swift</p>*/}
                                </li>
                            </div>
                            <div>
                                <li className="skill-icon">
                                    <img src={agile} alt=""/>
                                    {/*<p>Agile</p>*/}
                                </li>
                            </div>
                            <div>
                                <li className="skill-icon">
                                    <img src={figma} alt=""/>
                                    {/*<p>Figma</p>*/}
                                </li>
                            </div>
                            <div>
                                <li className="skill-icon">
                                    <img src={github} alt=""/>
                                    {/*<p>Github</p>*/}
                                </li>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="hero-right">
                        <div className="hero-img"><img src={profile} alt=""/></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Intro;