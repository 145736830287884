import React, {useEffect ,useRef, useCallback} from 'react';
import "../style/projects.css";
import AlQamarBanner from "../assets/case_study/Restaurant/rest banner.gif";
import {useTitle} from "../hooks/useTitle";
import banner from "../assets/case_study/Dap/dap banner.png";
import TableOfContents from "./TableOfContents";
import sketch1 from "../assets/case_study/Dap/wireframe 3.png";
import sketch2 from "../assets/case_study/Dap/wireframe.png";
import sketch3 from "../assets/case_study/Dap/wireframe 2.png"
import dotVote from "../assets/case_study/Dap/dot_vote.png";
import homeScreenWireframe from "../assets/case_study/Dap/finalized wireframe.png";
import detailedScreenWireframe from "../assets/case_study/Dap/detailed screen.png";
import brandingKit from "../assets/case_study/Dap/branding kit.png";
import DeenBanner from "../assets/case_study/IslamicApp/deen banner.gif";
import hiFi from "../assets/case_study/Dap/hi-fi.png";
import hiFiPrototype from "../assets/case_study/Dap/dapHiFi.gif";
import data from "../assets/case_study/Dap/data chart.png";
import userFlow from "../assets/case_study/Dap/user flow.png";

const projects = [
    {
        title: 'Restaurant App',
        description: 'A mobile app that enhances the customer experience for Al-Qamar fast food restaurant.',
        banner: AlQamarBanner,
        link: '/al-qamar',
        button: 'View Case Study'
    },
    {
        title: 'Deen',
        description: 'A Islamic mobile app for the novice internet users, individual with limited digital literacy.',
        banner: DeenBanner,
        link: '/deen',
        button: 'View Case Study'
    },
];

function Dap(props) {
    useTitle('Detriot Accessibility Project');

    const mainContentRef = useRef(null);
    const scrollIntoView = useCallback(() => {
        mainContentRef.current.scrollIntoView();
    }, []);
    useEffect(() => {
        scrollIntoView();
    }, [scrollIntoView]);

    return (
        <main>
            <div className="case-study-banner dap" ref={mainContentRef}>
                <div className="case-study-left">
                    <h1 className="case-study-title">Detroit Accessibility Project</h1>
                    <p className="case-study-brief">An responsive website that provides comprehensive venue accessibility
                        information in Detroit.</p>
                </div>
                <div className="case-study-right">
                    <img src={banner} alt="browser screen" loading="lazy"/>
                </div>
            </div>
            <div className="project-container">
                {/*<div className="side-bar">*/}
                {/*    <TableOfContents />*/}
                {/*</div>*/}
                <div className="project-content">
                    <section className="project-overview" id="projectOverview">
                        <div className="three-rows">
                            <div>
                                <h3 className="project-subheading">Team</h3>
                                <p className="project-des"> <strong>Farjana Chadni</strong>, Charlotte Bachelor, Emily Lin, Kennedi Cosma, Addison Walton,
                                    Mohamed Malik, Isabelle Derocher</p>
                            </div>
                            <div>
                                <h3 className="project-subheading">Tools</h3>
                                <p className="project-des">Figma, Adobe XD, Adobe Illustrator, JavaScript, WordPress,
                                    Crocoblock, Elementor</p>
                            </div>
                            <div>
                                <h3 className="project-subheading">Project Duration</h3>
                                <p className="project-des">January 2022 - November 2022</p>
                            </div>
                        </div>
                        <h3 className="project-subheading">Overview</h3>
                        <p className="project-des">Detroit Accessibility Project (DAP) is a nonprofit organization meant
                            to act as a resource on venue accessibility in Detroit. The founder Charlotte Bachelor believes
                            that everyone (with or without disabilities) should be able to enjoy the sights Detroit has
                            to offer without feeling like an afterthought. <strong>
                                <a href="https://www.detroitaccessibility.org/" target='_blank' rel="noreferrer">Click to view the website</a>
                            </strong>
                        </p>
                        <h3 className="project-subheading">Problem</h3>
                        <p className="project-des">
                            <strong>There is no one-stop-shop for accessibility information for Downtown Detroit. </strong>
                            Information on the websites of individual stadiums, theaters, and museums can be challenging
                            to find, or non-existent. When these venues do provide accessibility information, it often times
                            only applies to those with mobility issues but doesn’t address sensory or other non-visible
                            disabilities. Without this information, navigating Detroit can be laborious for someone with
                            a disability, and it excludes them from cultural spaces.
                        </p>
                        <h3 className="project-subheading">Goal</h3>
                        <p className="project-des">
                            {/*The founder Charlotte Bachelor envisioned a cohesive digital space that bridges the gap between*/}
                            {/*venues and visitors, allowing everyone to experience all Detroit has to offer, stress-free.*/}
                            {/*<ul className="project-des">*/}
                            {/*    <li>Create a sustainable accessibility guide for venues in Detroit; Set a precedent and bring accessibility to the forefront.</li>*/}
                            {/*    <li>Bring accessibility to the forefront and give disabled individuals an equal shot to engage and participate with their communities.</li>*/}
                            {/*    /!*<li>Create sustainable accessibility infrastructure for communities.</li>*!/*/}
                            {/*    /!*<li>Set a precedent and bring accessibility to the forefront.</li>*!/*/}
                            {/*</ul>*/}
                            <p className="project-des">Create a sustainable accessibility guide for venues in Detroit; Bring
                                accessibility to the forefront and give disabled individuals an equal shot to engage and
                                participate with their communities.</p>
                        </p>
                        <h3 className="project-subheading">Solution</h3>
                        <div className="image01"><img src={hiFi} alt="high fidelity prototype" loading="lazy"/></div>
                    </section>
                    <br/>
                    <hr/>
                    <section className="project-research" id="understandingUser">
                        <h3 className="project-subheading">Target Audience</h3>
                        {/*<p className="project-des">The target audiences for the Detroit Accessibility Project website are as follows:</p>*/}
                        <ul className="project-des">
                            <li>Individuals with disabilities, including physical, sensory, and cognitive disabilities</li>
                            <li>Family, friends, and caregivers of individuals with disabilities</li>
                            {/*<li>Tourists and visitors to Detroit</li>*/}
                        </ul>
                        <p className="project-des">Given the diverse and inclusive target audience, it is critical that
                            the DAP website is designed with accessibility as a high priority. People with disabilities
                            often face difficulties in accessing information and resources online due to its inaccessible
                            features. Thus, it is essential that the DAP website is user-friendly and accessible. By prioritizing
                            accessibility in the development of the DAP website, DAP can appeal to its audiences and demonstrate
                            its commitment to inclusive design.
                        </p>
                        <div className="two-rows">
                            <div>
                                <h3 className="project-subheading">Research Data</h3>
                                <p className="project-des">This data was pulled from the 2019 American Community Survey
                                    which provided detailed information disability.</p>
                            </div>
                            <div className="image01"><img src={data} alt="high fidelity prototype" loading="lazy"/></div>
                        </div>

                        <h3 className="project-subheading">Ideation</h3>
                        <p className="project-des">
                            {/*<p>With a clear understanding of the target audience, we stared brainstorming*/}
                            {/*    idea by doing crazy 8 and creating low-fidelity sketches. After that, we collaborated on the goals,*/}
                            {/*    opportunities, visual inspiration, and potential frustrations which helped us narrow down the*/}
                            {/*    type of features the user would want.</p>*/}
                            <ul>
                                <li>How might we help visitors sort for the results they are looking for?</li>
                                <li>How might we provide a filtering system that accounts for a diverse list of accessibility needs?</li>
                                <li>How might we organize information in a way that makes it easy and effortless to find?</li>
                            </ul>
                        </p>
                        <div className="two-rows">
                            <div className="image01"><img src={sketch1} alt="sketch" loading="lazy"/></div>
                            <div className="image01"><img src={sketch2} alt="sketch" loading="lazy"/></div>
                            <div className="image01"><img src={sketch3} alt="sketch" loading="lazy"/></div>
                            <div className="image01"><img src={dotVote} alt="dot voting" loading="lazy"/></div>
                        </div>

                        <h2 className="project-subheading" id="targetAudience">User Flow</h2>
                        <div className="image01"><img src={userFlow} alt="user flow diagram" loading="lazy"/></div>
                    </section>
                    <br/>
                    <hr/>
                    <section className="project-sketch" id="startingDesign">
                        <h3 className="project-subheading">Wireframe</h3>
                        {/*<p className="project-des">We created the wireframe which combined the effective design and*/}
                        {/*    features of the website.</p>*/}
                        <div className="image01"><img src={homeScreenWireframe} alt="home screen wireframe" loading="lazy"/></div>
                        <div className="image01"><img src={detailedScreenWireframe} alt="detail screen wireframe" loading="lazy"/></div>

                        <h3 className="project-subheading">Branding Kit</h3>
                        {/*<p className="project-des">After the wireframes were completed, we collaborated to develop accessible*/}
                        {/*    branding.*/}
                        {/*</p>*/}
                        <div className="image01"><img src={brandingKit} alt="branding kit" loading="lazy"/></div>
                    </section>
                    <br/>
                    <hr/>
                    <section className="project-refine" id="refiningDesign">
                        {/*<h2 className="project-heading">Refining The Design</h2>*/}
                        <h3 className="project-subheading">High-fidelity Prototype</h3>
                        {/*<p className="project-des">The prototype demonstrate the functionality and user flow of the website.*/}
                        {/*    This presents the design improvements made to the wireframe, with the goal of creating a*/}
                        {/*    seamless and convenient user experience.*/}
                        {/*</p>*/}
                        <div className="image01"><img src={hiFiPrototype} alt="high fidelity prototype" loading="lazy"/></div>
                    </section>
                    <br/>
                    <hr/>
                    <section className="project-next" id="goingForward">
                        {/*<h2 className="project-heading">Going Forward</h2>*/}
                        <h3 className="project-subheading">Obstacles</h3>
                        <ul className="project-des">
                            <li className="project-des"><span>Challenge in gathering data:</span> Despite the team’s efforts to reach out
                                to the venues for accurate accessibility information, the response was slow and inconsistent.
                            </li>
                            <li className="project-des"><span>Challenge in selecting the development platform:</span> Initially
                                website was developed hand coded, However, due to ease of maintenance for client, we rebuild
                                the website using WordPress.
                            </li>
                            <li className="project-des"><span>Challenge with a steep learning curve:</span> As we transitioned
                                to WordPress, we had to utilize the third-party plugin Crocoblock which is a plugin I was
                                not experienced in working nor the DAP team.
                            </li>
                        </ul>

                        {/*<h3 className="project-subheading">Impact</h3>*/}
                        {/*<p className="project-des">Despite all the obstacles, the final development of the website was well*/}
                        {/*    received by the client. As the website has been successfully launched, this demonstrates our*/}
                        {/*    commitment to promoting accessibility and inclusivity. We believe that The Detroit*/}
                        {/*    Accessibility project will make a significant impact on the Detroit community as the database*/}
                        {/*    expands.*/}
                        {/*</p>*/}
                        {/*<ul className="project-des">*/}
                        {/*    <li>Providing a stress-free environment to learn about accessibility resources.</li>*/}
                        {/*    <li>Bridging the gap and bringing communities together</li>*/}
                        {/*    <li>Empowering accessibility and inclusivity</li>*/}
                        {/*</ul>*/}
                        <h3 className="project-subheading">What I learned </h3>
                        <ul className="project-des">
                            <li> <span>Adaptability and Flexibility:</span> The challenges faced during the development process allowed me to understand the importance of being adaptable and flexible in project execution.</li>
                            <li> <span>Technical Proficiency in new tools:</span> The steep learning curve of the new development tool for the project allowed me to appreciate the continuous learning process.</li>
                            <li> <span>Collaboration and communication:</span> Obstacles faced during the project built allowed me to understand that communication is extremely crucial in overcoming challenges.</li>
                        </ul>

                        <h2 className="project-subheading">Next steps</h2>
                        <ul className="project-des">
                            <li><strong>Social Media:</strong> The Detroit Accessibility Project will soon expand through the creation of a Facebook, Instagram, and Twitter account.</li>
                            <li><strong>App Development:</strong> Once the project picks up momentum in the community, the app development process would begin.</li>
                            <li><strong>Expansion:</strong> More features can be added to the website, like an interactive map. We hope advancements in the project will generate media attention and inspire more cities.</li>
                        </ul>
                    </section>
                    <section>
                        <br/><br/><br/>
                        <h1 className="project-heading">Other Projects</h1>
                        <div>
                            <div className="projects-min">
                                {projects.map(project => (
                                    <div className="project">
                                        <img className="banner-image project-deen" src={project.banner} key={project.title} alt=""/>
                                        <div className="project-info">
                                            <p className="project-title">{project.title}</p>
                                            <p className="project-brief">{project.description}</p>
                                            <a href={project.link}>
                                            <span className="project-link">
                                              <span className="button-text">{project.button}</span>
                                            </span>
                                            </a>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </main>
    );
}

export default Dap;