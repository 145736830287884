import React, {useEffect ,useRef, useCallback} from 'react';
import "../style/projects.css";
import '../style/home.css';
import { useTitle } from "../hooks/useTitle";
// import banner from "../assets/case_study/Restaurant/rest banner.svg";
import bannerpng from "../assets/case_study/Restaurant/rest banner.png";
import persona1 from "../assets/case_study/Restaurant/sam.png";
import persona2 from "../assets/case_study/Restaurant/Group 22.png";
import wireframe1 from "../assets/case_study/Restaurant/Group 75.png";
import wireframe2 from "../assets/case_study/Restaurant/Group 79.png";
import wireframe3 from "../assets/case_study/Restaurant/Group 80.png";
import homeBefore from "../assets/case_study/Restaurant/homeScreenBefore.png";
import homeAfter from "../assets/case_study/Restaurant/homeScreenAfter.png";
import searchBefore from "../assets/case_study/Restaurant/searchScreenBefore.png";
import searchAfter from "../assets/case_study/Restaurant/searchScreenAfter.png";
import orderBefore from "../assets/case_study/Restaurant/orderScreenBefore.png";
import orderAfter from "../assets/case_study/Restaurant/orderScreenAfter.png";
import cartBefore from "../assets/case_study/Restaurant/cartScreenBefore.png";
import cartAfter from "../assets/case_study/Restaurant/cartScreenAfter.png";
import trackScreen from "../assets/case_study/Restaurant/trackScreen.png";
import checkoutScreen from "../assets/case_study/Restaurant/checkoutScreen.png";
import prototypeVideo from "../assets/case_study/Restaurant/Prototype Video.mov";
import userFlow from "../assets/case_study/Restaurant/user flow.png";
// import solution from "../assets/case_study/Restaurant/solution.svg";
import solutionpng from "../assets/case_study/Restaurant/solution.png";
import TableOfContents from "./TableOfContents";
import DeenBanner from "../assets/case_study/IslamicApp/deen banner.gif";
// import {IoIosArrowRoundForward} from "react-icons/io";
// import designBanner from "../assets/case_study/GD468/interaction_design_banner.png";
import dapBanner from "../assets/case_study/dap banner.png";

const projects = [
    {
        title: 'Deen',
        description: 'A Islamic mobile app for the novice internet users, individual with limited digital literacy.',
        banner: DeenBanner,
        link: '/deen',
        button: 'View Case Study'
    },
    {
        title: 'DAP website',
        description: 'An responsive website that provides comprehensive venue accessibility information in Detroit.',
        banner: dapBanner,
        link: '/dap',
        button: 'View Case Study'
    },
];


const AlQamar = React.memo(({props}) => {
    useTitle('Restaurant App');

    const mainContentRef = useRef(null);
    const scrollIntoView = useCallback(() => {
        mainContentRef.current.scrollIntoView();
    }, []);
    useEffect(() => {
        scrollIntoView();
    }, [scrollIntoView]);

    return (
        <div>
            <div className="case-study-banner rest" ref={mainContentRef}>
                <div className="case-study-left">
                    <h1 className="case-study-title">Restaurant App</h1>
                    <p className="case-study-brief">A mobile app that enhances the customer experience for Al-Qamar fast food restaurant.</p>
                </div>
                <div className="case-study-right">
                    <img src={bannerpng} alt="phone screens" loading="lazy"/>
                </div>
            </div>
            <div className="project-container">
                {/*<div className="side-bar">*/}
                {/*    <TableOfContents />*/}
                {/*</div>*/}
                <div className="project-content">
                    <section className="project-overview" id="projectOverview">
                        <div className="three-rows">
                            <div>
                                <h3 className="project-subheading">My Role</h3>
                                <p className="project-des">Solo UX/UI researcher & designer</p>
                            </div>
                            <div>
                                <h3 className="project-subheading">Tools</h3>
                                <p className="project-des">Figma</p>
                            </div>
                            <div>
                                <h3 className="project-subheading">Project Duration</h3>
                                <p className="project-des">Three weeks</p>
                            </div>
                        </div>
                        <h3 className="project-subheading">Overview</h3>
                        <p className="project-des">A popular restaurant in my hometown has yet to establish a digital presence in
                            the form of an mobile app. In today's digital age, having a restaurant app can greatly benefit both
                            the business and its customers. I saw the opportunity to design an app for this restaurant to
                            address this gap and enhance the customer experience.</p>
                        <h2 className="project-subheading">Problem</h2>
                        <p className="project-des">The restaurant lacks a digital presence, causing inconvenience for customers
                            who wish to view menus, place orders, and track the status of their orders. This can lead to
                            a less satisfactory customer experience.</p>
                        <h2 className="project-subheading">Goal</h2>
                        <p className="project-des">Provide a convenient way to browse menus, place orders electronically, and track order status
                            in real-time to improves the customer experience.</p>
                        {/*<p className="project-des">The mobile app will provide the users with a convenient way to browse*/}
                        {/*    menus, place orders electronically, and track order status in real-time which will affect how*/}
                        {/*     the restaurant promotes their digital presence by improves the customer experience.</p>*/}
                        <h2 className="project-subheading">Solution</h2>
                        <div className="image01"><img src={solutionpng} alt="solution" loading="lazy"/></div>
                    </section>

                    <br/>
                    <hr/>

                    <section className="project-research" id="understandingUser">
                        {/*<h1 className="project-heading">Understanding The User</h1>*/}
                        <h2 className="project-subheading">Primary Research</h2>
                        <p className="project-des">To gather insights and understand the needs of local customers, I
                            conducted few informal interviews and created an empathy map.</p>
                        {/*<p>This helped me identify the pain*/}
                        {/*    points and frustrations that customers experience when interacting with the restaurant.</p>*/}
                        <ul className="project-des">
                            <li>Difficulty accessing restaurant information and placing orders</li>
                            <li>Limited knowledge about food options and dietary restrictions</li>
                        </ul>
                        <p className="project-des">Through the development of a restaurant app, I aimed to address these
                            pain points by providing features such as the ability to view menus, place orders, track order
                            status, and learn about food items and dietary restrictions.</p>
                        <h2 className="project-subheading">Personas</h2>
                        <div className="image01"><img src={persona1} alt="persona 1" loading="lazy"/></div>
                        <div className="image01"><img src={persona2} alt="persona 2" loading="lazy"/></div>
                        <h3>User Flow</h3>
                        <div className="image01"><img src={userFlow} alt="user flow diagram" loading="lazy"/></div>
                    </section>

                    <br/>
                    <hr/>

                    <section className="project-sketch" id="startingDesign">
                        {/*<h1 className="project-heading">Starting The Design</h1>*/}

                        <h2 className="project-subheading">Wireframe</h2>
                        {/*<p className="project-des">To visually represent my idea for the app, I created digital wireframes*/}
                        {/*    that showed the layout and functionality of the app.</p>*/}
                        <div className="image01"><img src={wireframe1} alt="wireframe of home screen 1" loading="lazy"/></div>
                        <div className="image01"><img src={wireframe2} alt="wireframe of home screen 2" loading="lazy"/></div>
                        <div className="image01"><img src={wireframe3} alt="wireframe of order screen" loading="lazy"/></div>

                        <h2 className="project-subheading">Usability Studies</h2>
                        {/*<p className="project-des">I conducted usability studies to gather user feedback and improve the*/}
                        {/*    design of the restaurant app prototype. I observed 5 different users interact with the*/}
                        {/*    lo-fi prototype.</p>*/}
                        <ul className="project-des">
                            <li>It was observed that
                                <span style={{ color: 'red' }} > 3</span> out of
                                <span style={{ color: 'red' }} > 5</span> participants
                                <span style={{ color: 'blue' }}> were interested in learning about how other users would rate the food items</span>.
                                This means that
                                <span style={{ color: 'green' }}> participants value the opinions of other users when deciding what to order</span>.
                            </li>
                            <li>It was observed that
                                <span style={{ color: 'red' }} > 4</span> out of
                                <span style={{ color: 'red' }} > 5</span> participants
                                <span style={{ color: 'blue' }}> wants to see all the available categories without having to scroll down</span>.
                                This means that
                                <span style={{ color: 'green' }}> participants want an easy and convenient way to browse all available categories</span>.
                            </li>
                            <li>It was observed that
                                <span style={{ color: 'red' }} > 2</span> out of
                                <span style={{ color: 'red' }} > 5</span> participants
                                <span style={{ color: 'blue' }}> are accustomed to seeing automatic search results as they type in the search bar</span>.
                                This means that
                                <span style={{ color: 'green' }}> participants expects a search bar to provide real-time search results</span>.
                            </li>
                        </ul>
                    </section>

                    <br/>
                    <hr/>

                    <section className="project-refine" id="refiningDesign">
                        {/*<h1 className="project-heading">Refining The Design</h1>*/}

                        {/*<h2 className="project-subheading">Mockups</h2>*/}
                        {/*<p className="project-des">Based on the insights from the usability study, I was able to refine*/}
                        {/*    the design and create mockups that better meet the needs of the users.*/}
                        {/*</p>*/}

                        <h2 className="project-subheading">Home Screen</h2>
                        <p className="project-des">It became evident through the usability study that users were seeking
                            a simpler way to browse all available categories without the need to scroll down. In response,
                            I added a category section featuring a vertical scroll and grid view, a popular section to
                            minimize overwhelming users, and a promotion section to highlight special offers. These
                            improvements not only addressed user needs, but also significantly enhanced the overall user
                            experience.
                        </p>
                        <div className="container">
                            <div className="image-container">
                                <img src={homeBefore} alt="home screen before usability study" loading="lazy" className="image25"/>
                            </div>
                            <div className="image-container">
                                <img src={homeAfter} alt="home screen after usability study" loading="lazy" className="image25"/>
                            </div>
                        </div>

                        <h2 className="project-subheading">Search Screen</h2>
                        <p className="project-des">Two out of five participants were accustomed to seeing automatic search
                            results as they typed in the search bar. This indicates that these participants expected
                            real-time search results from the search bar. In response, I added search suggestions, filter
                            options, and real-time search results. These enhancements enhance the overall user experience
                            by providing users with the search results they expect and desire.
                        </p>
                        <div className="container">
                            <div className="image-container">
                                <img src={searchBefore} alt="search screen before usability study" loading="lazy" className="image25"/>
                            </div>
                            <div className="image-container">
                                <img src={searchAfter} alt="search screen after usability study" loading="lazy" className="image25"/>
                            </div>
                        </div>

                        <h2 className="project-subheading">Order Screen</h2>
                        <p className="project-des">The order screen originally had an overwhelming amount of information
                            and a lack of hierarchy in the design and layout, causing confusion for users. To address
                            this issue, I made improvements to the design by only including necessary information and
                            adding hierarchy to the layout. These changes enhance the overall user experience by making
                            the order process more clear and intuitive for users.
                        </p>
                        <div className="container">
                            <div className="image-container">
                                <img src={orderBefore} alt="order screen before usability study" loading="lazy" className="image25"/>
                            </div>
                            <div className="image-container">
                                <img src={orderAfter} alt="order screen after usability study" loading="lazy" className="image25"/>
                            </div>
                        </div>

                        <h2 className="project-subheading">Cart Screen</h2>
                        <p className="project-des">During the usability study, users raised concerns about not being able
                            to see the total price on the cart screen. In response, I made an improvement to show the calculated
                            total price on the cart screen before users proceed to checkout. This change allows users to
                            have a better understanding of the total cost of their order before moving forward with the checkout
                            process.
                        </p>
                        <div className="container">
                            <div className="image-container">
                                <img src={cartBefore} alt="cart screen before usability study" loading="lazy" className="image25"/>
                            </div>
                            <div className="image-container">
                                <img src={cartAfter} alt="cart screen after usability study" loading="lazy" className="image25"/>
                            </div>
                        </div>

                        <h2 className="project-subheading">Checkout & Track Order Screen</h2>
                        <p className="project-des">To make checkout process more convenient for users. The app will track
                            their location and save their address, so they don't have to enter it every time they make a
                            purchase. I also created a tracking screen that shows a map view with the delivery location
                            and estimated arrival time, so users can stay informed about their order status and know when
                            to expect it to arrive.
                        </p>
                        <div className="container">
                            <div className="image-container">
                                <img src={checkoutScreen} alt="checkout screen" loading="lazy" className="image25"/>
                            </div>
                            <div className="image-container">
                                <img src={trackScreen} alt="track order screen" loading="lazy" className="image25"/>
                            </div>
                        </div>

                        <h2 className="project-subheading">High-fidelity Prototype</h2>
                        {/*<p className="project-des">To further demonstrate the functionality and user flow of the app, I*/}
                        {/*    created a walkthrough video of the high-fidelity prototype. The video showcases the main*/}
                        {/*    interactions of the app and will be used in the second round of usability testing. This case*/}
                        {/*    study presents the design process and improvements made to the restaurant app prototype, with*/}
                        {/*    the goal of creating a seamless and convenient user experience.*/}
                        {/*</p>*/}
                        <div className="video-walk">
                            <video controls>
                                <source src={prototypeVideo}/>
                            </video>
                        </div>

                        <h2 className="project-subheading">Inclusivity & Accessibility Considerations</h2>
                        <ul className="project-des">
                            <li><strong>Multi-Language:</strong> Consider including multi-language support to accommodate users who do not speak English.</li>
                            {/*<li><strong>Text-To-Speech:</strong> Consider using text-to-speech functionality to make the app more accessible to users*/}
                            {/*    with hearing impairments:</li>*/}
                            <li><strong>Dark Mode</strong> Consider offering a "dark mode" theme for users who prefer low light environments or
                                who may have light sensitivity</li>
                        </ul>
                    </section>

                    <br/>
                    <hr/>

                    <section className="project-next" id="goingForward">
                        {/*<h1 className="project-heading">Going Forward</h1>*/}

                        <h2 className="project-subheading">Takeaways</h2>
                        {/*<h3>Impact</h3>*/}
                        {/*<p className="project-des">The app idea was well-received and customers agreed that it would*/}
                        {/*    benefit them and make the process of ordering food from the restaurant easier and quicker.*/}
                        {/*    One participant mentioned that they would be able to easily use the app to place orders*/}
                        {/*    without having to wait on the phone. This feedback suggests that the app could be a valuable*/}
                        {/*    tool for users and enhance their experience with the restaurant.</p>*/}
                        <h3>What I learned</h3>
                        <p className="project-des">Through this project, I gained valuable skills in time management and
                            prioritization, as well as a deeper understanding of the importance of usability studies in
                            the design process. I learned how to gather user feedback and insights to inform the design
                            and create solutions that meet the needs of my target users. This experience was extremely
                            rewarding and helped me grow as a designer, expanding my horizons and giving me the confidence
                            to tackle future projects.
                        </p>

                        <h2 className="project-subheading">Next steps</h2>
                        <ul className="project-des">
                            <li><strong>Usability study II:</strong> Conduct another round of usability studies with users to gather feedback and ensure
                                that the current solution effectively addresses their pain points.</li>
                            <li><strong>Refine design:</strong> Based on the observations and feedback from the usability studies, further refine the
                                design and add more interactive features to enhance the user experience.</li>
                            <li><strong>Propose solution:</strong> Propose the app solution to the restaurant owner and offer my assistance in development
                                to bring this app to fruition and significantly improve the customer experience</li>
                        </ul>

                        <h2 className="project-subheading">Let’s connect!</h2>
                        <p className="project-des">If you have any feedback and constructive criticism,
                            please send me a message, email or connect via Linkedin; any and all feedback and criticism will be
                            highly appreciated. <br/><br/> Thank you for taking the time to read
                            the case study.
                        </p>
                    </section>
                    <section>
                        <br/><br/><br/>
                        <h1 className="project-heading">Other Projects</h1>
                        <div>
                            <div className="projects-min">
                                {projects.map(project => (
                                    <div className="project">
                                        <img className="banner-image project-deen" src={project.banner} key={project.title} alt=""/>
                                        <div className="project-info">
                                            <p className="project-title">{project.title}</p>
                                            <p className="project-brief">{project.description}</p>
                                            <a href={project.link}>
                                            <span className="project-link">
                                              <span className="button-text">{project.button}</span>
                                              {/*<span className="button-icon"><IoIosArrowRoundForward/></span>*/}
                                            </span>
                                            </a>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
});

export default AlQamar;