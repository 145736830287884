import React, {useEffect, useRef, useCallback} from 'react';
import designBanner from "../assets/case_study/GD468/interaction_design_banner.png";
import sosLab from "../assets/case_study/GD468/SOSlab.png";
import designIdea from "../assets/case_study/GD468/designIdea.jpg";
import designIdeaGroup from "../assets/case_study/GD468/designIdeasGroup.jpg";
import moodBoard from "../assets/case_study/GD468/Mood Board.png";
import userTaskFlow from "../assets/case_study/GD468/User Task Flow.png";
import lowfi from "../assets/case_study/GD468/Medium-Fidelity Wireframes.png";
import vid from "../assets/case_study/GD468/vid1.mov";
import {useTitle} from "../hooks/useTitle";
import TableOfContents from "./TableOfContents";
import AlQamarBanner from "../assets/case_study/Restaurant/rest banner.gif";
import DeenBanner from "../assets/case_study/IslamicApp/deen banner.gif";
// import banner from "../assets/case_study/dap banner.svg";
// import {IoIosArrowRoundForward} from "react-icons/io";


const projects = [
    {
        title: 'Deen',
        description: 'A Islamic mobile app for the novice internet users, individual with limited digital literacy.',
        banner: DeenBanner,
        link: '/deen',
        button: 'View Case Study'
    },
    {
        title: 'Restaurant App',
        description: 'A mobile app that enhances the customer experience for Al-Qamar fast food restaurant.',
        banner: AlQamarBanner,
        link: '/al-qamar',
        button: 'View Case Study'
    },
];


const InteractiveDesign = React.memo(({props}) => {
    useTitle('Reducing Food Waste');

    // const mainContentRef = useRef(null);
    // useEffect(() => {
    //     mainContentRef.current.scrollIntoView();
    // }, []);

    const mainContentRef = useRef(null);
    const scrollIntoView = useCallback(() => {
        mainContentRef.current.scrollIntoView();
    }, []);
    useEffect(() => {
        scrollIntoView();
    }, [scrollIntoView]);

    return (
        <main>
            <div className="case-study-banner design" ref={mainContentRef}>
                <div className="case-study-left">
                    <h1 className="case-study-title">Reducing Food Waste</h1>
                    <p className="case-study-brief">Interactive experience for the SOS lab to teach middle school students about the food waste.</p>
                </div>
                <div className="case-study-right">
                    <img src={designBanner} alt="Goals that will be implemented"/>
                </div>
            </div>
            <div className="project-container">
                {/*<div className="side-bar">*/}
                {/*    <TableOfContents />*/}
                {/*</div>*/}
                <div className="project-content">
                    <section className="project-overview" id="projectOverview">
                        <div className="three-rows">
                            <div>
                                <h3 className="project-subheading">Team</h3>
                                <p className="project-des">Me, one UX student and two graphic design students</p>
                            </div>
                            <div>
                                <h3 className="project-subheading">Tools</h3>
                                <p className="project-des">Figma, Spline & Adobe Illustrator</p>
                            </div>
                            <div>
                                <h3 className="project-subheading">Project Duration</h3>
                                <p className="project-des">4 interactive classroom sessions</p>
                            </div>
                        </div>
                        <h3 className="project-subheading">Overview</h3>
                        <p className="project-des">In my interactive design class, my team and I developed an interactive
                            learning experience on reducing food waste for middle school students. This experience was
                            created for the MSU museum's modular SOS lab, which is a museum room equipped with a 3D globe.
                            We hope that this interactive learning experience will help students better understand this
                            pressing issue and motivate them to make a positive impact in their own communities.</p>
                        <h3 className="project-subheading">The Problem</h3>
                        <p className="project-des">Food waste is a significant issue in the global community, with an
                            estimated one third of all food produced going to waste each year. This not only has
                            environmental consequences, but also contributes to food insecurity and economic inefficiency.</p>
                        <h3 className="project-subheading">The Goal</h3>
                        <p className="project-des">The goal of this interactive learning experience is to increase
                            awareness among middle school students about the impact of food waste and encourage them
                            to take action to reduce it in their own lives, in order to contribute to a more sustainable
                            and equitable global community.</p>
                    </section>
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 1280.000000 150.000000" preserveAspectRatio="none">
                        <g transform="translate(0.000000,400.000000) scale(0.100000,-0.100000)" fill="#ceb8a4" stroke="none">
                            <path d="M4939 3411 c-113 -22 -229 -116 -282 -229 -30 -62 -32 -75 -32 -172 0 -92 3 -112 26 -160 36 -77 71 -115 137 -147 49 -24 69 -28 142 -28 107 0 164 21 218 79 67 73 79 158 36 245 -36 71 -86 104 -168 109 -49 3 -70 -1 -106 -20 -53 -27 -84 -74 -74 -113 l7 -26 28 34 c36 44 108 63 172 46 110 -30 152 -177 74 -265 -49 -57 -100 -78 -188 -78 -67 -1 -81 3 -125 29 -91 57 -141 181 -132 327 14 208 205 360 411 327 127 -21 230 -71 426 -208 207 -145 349 -220 531 -279 269 -88 452 -88 720 0 183 60 325 135 531 279 196 137 299 187 426 208 206 33 397 -119 411 -327 9 -146 -41 -270 -132 -327 -44 -26 -58 -30 -125 -29 -88 0 -139 21 -188 78 -78 88 -36 235 74 265 64 17 136 -2 172 -46 l28 -34 7 26 c10 39 -21 86 -74 113 -36 19 -57 23 -106 20 -82 -5 -132 -38 -168 -109 -43 -87 -31 -172 36 -245 54 -58 111 -79 218 -79 140 0 221 51 279 175 23 48 26 68 26 160 0 97 -2 110 -32 172 -40 86 -110 156 -194 196 -176 84 -376 33 -646 -165 -277 -203 -488 -305 -738 -358 -202 -42 -425 -2 -690 124 -135 65 -208 110 -378 234 -226 165 -403 228 -558 198z"/>
                            <path d="M4444 3070 c-49 -20 -66 -77 -39 -129 29 -56 125 -58 154 -3 42 79 -33 165 -115 132z m66 -48 c18 -14 21 -25 16 -45 -8 -35 -49 -46 -76 -22 -25 23 -25 40 0 65 25 25 32 25 60 2z"/>
                            <path d="M8262 3055 c-54 -45 -42 -124 23 -151 62 -26 125 20 125 90 0 36 -39 76 -81 82 -28 5 -41 1 -67 -21z m88 -35 c25 -25 25 -42 0 -65 -27 -24 -68 -13 -76 22 -5 20 -2 31 16 45 28 23 35 23 60 -2z"/>
                            <path d="M16 3017 c-24 -18 -17 -48 14 -64 20 -10 453 -12 2148 -10 2089 2 2124 2 2138 21 18 25 18 31 -2 50 -14 14 -223 16 -2148 16 -1747 0 -2135 -2 -2150 -13z"/>
                            <path d="M8486 3014 c-20 -19 -20 -25 -2 -50 14 -19 49 -19 2138 -21 2246 -3 2168 -4 2176 42 10 47 103 45 -2164 45 -1925 0 -2134 -2 -2148 -16z"/>
                            <path d="M5492 2948 c-52 -19 -142 -93 -142 -116 0 -6 11 -24 24 -39 23 -27 28 -28 113 -27 75 0 107 7 218 42 72 23 155 45 185 48 88 10 91 18 19 52 -137 65 -310 82 -417 40z m368 -43 l55 -18 -80 -12 c-44 -7 -127 -24 -185 -40 -111 -28 -213 -36 -232 -17 -22 22 39 69 127 99 55 18 243 11 315 -12z"/>
                            <path d="M7004 2951 c-34 -10 -88 -30 -119 -46 -65 -31 -61 -39 25 -49 30 -3 114 -25 185 -48 111 -35 143 -42 218 -42 85 -1 90 0 113 27 13 15 24 33 24 39 0 24 -90 97 -144 117 -72 27 -212 28 -302 2z m293 -50 c62 -28 102 -66 85 -83 -20 -20 -122 -11 -248 21 -66 17 -149 34 -183 37 -43 5 -58 10 -48 16 77 43 305 48 394 9z"/>
                        </g>
                    </svg>
                    <section className="project-research">
                        <h1 className="project-heading">Brainstorm</h1>

                        <div className="two-rows-des">
                            <div>
                                <h2 className="project-subheading">Field Trip to SOS lab</h2>
                                <p className="project-des">On the first day of the project, our professor took us to the SOS lab
                                    and showed us the globe and some examples to inspire our design.</p>
                            </div>
                            <div className="image01"><img src={sosLab} alt="Sos lab" loading="lazy"/></div>
                        </div>
                        <div className="two-rows-des">
                            <div>
                                <h2 className="project-subheading">Brain dump</h2>
                                <p className="project-des">After the field trip, my team and I brainstormed a variety of global
                                    issues, including climate change, poverty, culture, internet, and consumption.
                                    We wrote our ideas on sticky notes. </p>
                            </div>
                            <div className="image01"><img src={designIdea} alt="board of ideas" loading="lazy"/></div>
                        </div>
                        <div className="two-rows-des">
                            <div>
                                <h2 className="project-subheading">Define</h2>
                                <p className="project-des">Once we had topic of interest, we grouped them together based on
                                    common themes. After that, we held a vote to decide on the topic we wanted to create an
                                    innovative learning experience. After a careful consideration, we ultimately decided on
                                    food waste as the focus of our project.</p>
                            </div>
                            <div className="image01"><img src={designIdeaGroup} alt="selected design idea" loading="lazy"/></div>
                        </div>
                    </section>
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 1280.000000 150.000000" preserveAspectRatio="none">
                        <g transform="translate(0.000000,400.000000) scale(0.100000,-0.100000)" fill="#ceb8a4" stroke="none">
                            <path d="M4939 3411 c-113 -22 -229 -116 -282 -229 -30 -62 -32 -75 -32 -172 0 -92 3 -112 26 -160 36 -77 71 -115 137 -147 49 -24 69 -28 142 -28 107 0 164 21 218 79 67 73 79 158 36 245 -36 71 -86 104 -168 109 -49 3 -70 -1 -106 -20 -53 -27 -84 -74 -74 -113 l7 -26 28 34 c36 44 108 63 172 46 110 -30 152 -177 74 -265 -49 -57 -100 -78 -188 -78 -67 -1 -81 3 -125 29 -91 57 -141 181 -132 327 14 208 205 360 411 327 127 -21 230 -71 426 -208 207 -145 349 -220 531 -279 269 -88 452 -88 720 0 183 60 325 135 531 279 196 137 299 187 426 208 206 33 397 -119 411 -327 9 -146 -41 -270 -132 -327 -44 -26 -58 -30 -125 -29 -88 0 -139 21 -188 78 -78 88 -36 235 74 265 64 17 136 -2 172 -46 l28 -34 7 26 c10 39 -21 86 -74 113 -36 19 -57 23 -106 20 -82 -5 -132 -38 -168 -109 -43 -87 -31 -172 36 -245 54 -58 111 -79 218 -79 140 0 221 51 279 175 23 48 26 68 26 160 0 97 -2 110 -32 172 -40 86 -110 156 -194 196 -176 84 -376 33 -646 -165 -277 -203 -488 -305 -738 -358 -202 -42 -425 -2 -690 124 -135 65 -208 110 -378 234 -226 165 -403 228 -558 198z"/>
                            <path d="M4444 3070 c-49 -20 -66 -77 -39 -129 29 -56 125 -58 154 -3 42 79 -33 165 -115 132z m66 -48 c18 -14 21 -25 16 -45 -8 -35 -49 -46 -76 -22 -25 23 -25 40 0 65 25 25 32 25 60 2z"/>
                            <path d="M8262 3055 c-54 -45 -42 -124 23 -151 62 -26 125 20 125 90 0 36 -39 76 -81 82 -28 5 -41 1 -67 -21z m88 -35 c25 -25 25 -42 0 -65 -27 -24 -68 -13 -76 22 -5 20 -2 31 16 45 28 23 35 23 60 -2z"/>
                            <path d="M16 3017 c-24 -18 -17 -48 14 -64 20 -10 453 -12 2148 -10 2089 2 2124 2 2138 21 18 25 18 31 -2 50 -14 14 -223 16 -2148 16 -1747 0 -2135 -2 -2150 -13z"/>
                            <path d="M8486 3014 c-20 -19 -20 -25 -2 -50 14 -19 49 -19 2138 -21 2246 -3 2168 -4 2176 42 10 47 103 45 -2164 45 -1925 0 -2134 -2 -2148 -16z"/>
                            <path d="M5492 2948 c-52 -19 -142 -93 -142 -116 0 -6 11 -24 24 -39 23 -27 28 -28 113 -27 75 0 107 7 218 42 72 23 155 45 185 48 88 10 91 18 19 52 -137 65 -310 82 -417 40z m368 -43 l55 -18 -80 -12 c-44 -7 -127 -24 -185 -40 -111 -28 -213 -36 -232 -17 -22 22 39 69 127 99 55 18 243 11 315 -12z"/>
                            <path d="M7004 2951 c-34 -10 -88 -30 -119 -46 -65 -31 -61 -39 25 -49 30 -3 114 -25 185 -48 111 -35 143 -42 218 -42 85 -1 90 0 113 27 13 15 24 33 24 39 0 24 -90 97 -144 117 -72 27 -212 28 -302 2z m293 -50 c62 -28 102 -66 85 -83 -20 -20 -122 -11 -248 21 -66 17 -149 34 -183 37 -43 5 -58 10 -48 16 77 43 305 48 394 9z"/>
                        </g>
                    </svg>
                    <section className="project-sketch" id="startingDesign">
                        <h1 className="project-heading">Starting The Design</h1>
                        <h2 className="project-subheading">Mood board</h2>
                        <p className="project-des">On the second day, we spent time getting inspiration and creating mood
                            boards. My section of the mood board focused on realism and depicted the
                            impact of food waste on a global scale. It showed how some parts of the world have an abundance
                            of food while others have nothing. I wanted to represent the real-world consequences of food
                            waste and inspire action to address this issue.</p>
                        <div className="image01"><img src={moodBoard} alt="mood board" loading="lazy"/></div>

                        <h2 className="project-subheading">User Task Flow</h2>
                        <p className="project-des">After creating the mood boards, our team began discussing potential
                            interactions such as gifs, videos, and visual elements. The user task flow is as follows: when
                            the student enters the SOS Lab, a map appears on the 3d globe displaying the top five countries with the greatest contribution to food waste.
                            The user is then prompted on the kiosk to select a location to view data on food waste.
                            Once the user has finished viewing the information, they are given the option to take action
                            to prevent food waste or not. If they choose to take action, they will be shown a video of
                            food from the top five countries being redistributed around the globe. If they choose not to
                            take action, they will see a video of someone drowning in food.</p>
                        <div className="image01"><img src={userTaskFlow} alt="user task flow diagram" loading="lazy"/></div>

                        <h2 className="project-subheading">Wireframe</h2>
                        <p className="project-des">After determining the user task flow, we created wireframes to better
                            understand and visualize our idea. This allowed us to see how the project would look and
                            function in practice, and make any necessary changes to ensure that it met our goals and the
                            needs of our users.</p>
                        <div className="image01"><img src={lowfi} alt="Wireframe" loading="lazy"/></div>
                    </section>
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 1280.000000 150.000000" preserveAspectRatio="none">
                        <g transform="translate(0.000000,400.000000) scale(0.100000,-0.100000)" fill="#ceb8a4" stroke="none">
                            <path d="M4939 3411 c-113 -22 -229 -116 -282 -229 -30 -62 -32 -75 -32 -172 0 -92 3 -112 26 -160 36 -77 71 -115 137 -147 49 -24 69 -28 142 -28 107 0 164 21 218 79 67 73 79 158 36 245 -36 71 -86 104 -168 109 -49 3 -70 -1 -106 -20 -53 -27 -84 -74 -74 -113 l7 -26 28 34 c36 44 108 63 172 46 110 -30 152 -177 74 -265 -49 -57 -100 -78 -188 -78 -67 -1 -81 3 -125 29 -91 57 -141 181 -132 327 14 208 205 360 411 327 127 -21 230 -71 426 -208 207 -145 349 -220 531 -279 269 -88 452 -88 720 0 183 60 325 135 531 279 196 137 299 187 426 208 206 33 397 -119 411 -327 9 -146 -41 -270 -132 -327 -44 -26 -58 -30 -125 -29 -88 0 -139 21 -188 78 -78 88 -36 235 74 265 64 17 136 -2 172 -46 l28 -34 7 26 c10 39 -21 86 -74 113 -36 19 -57 23 -106 20 -82 -5 -132 -38 -168 -109 -43 -87 -31 -172 36 -245 54 -58 111 -79 218 -79 140 0 221 51 279 175 23 48 26 68 26 160 0 97 -2 110 -32 172 -40 86 -110 156 -194 196 -176 84 -376 33 -646 -165 -277 -203 -488 -305 -738 -358 -202 -42 -425 -2 -690 124 -135 65 -208 110 -378 234 -226 165 -403 228 -558 198z"/>
                            <path d="M4444 3070 c-49 -20 -66 -77 -39 -129 29 -56 125 -58 154 -3 42 79 -33 165 -115 132z m66 -48 c18 -14 21 -25 16 -45 -8 -35 -49 -46 -76 -22 -25 23 -25 40 0 65 25 25 32 25 60 2z"/>
                            <path d="M8262 3055 c-54 -45 -42 -124 23 -151 62 -26 125 20 125 90 0 36 -39 76 -81 82 -28 5 -41 1 -67 -21z m88 -35 c25 -25 25 -42 0 -65 -27 -24 -68 -13 -76 22 -5 20 -2 31 16 45 28 23 35 23 60 -2z"/>
                            <path d="M16 3017 c-24 -18 -17 -48 14 -64 20 -10 453 -12 2148 -10 2089 2 2124 2 2138 21 18 25 18 31 -2 50 -14 14 -223 16 -2148 16 -1747 0 -2135 -2 -2150 -13z"/>
                            <path d="M8486 3014 c-20 -19 -20 -25 -2 -50 14 -19 49 -19 2138 -21 2246 -3 2168 -4 2176 42 10 47 103 45 -2164 45 -1925 0 -2134 -2 -2148 -16z"/>
                            <path d="M5492 2948 c-52 -19 -142 -93 -142 -116 0 -6 11 -24 24 -39 23 -27 28 -28 113 -27 75 0 107 7 218 42 72 23 155 45 185 48 88 10 91 18 19 52 -137 65 -310 82 -417 40z m368 -43 l55 -18 -80 -12 c-44 -7 -127 -24 -185 -40 -111 -28 -213 -36 -232 -17 -22 22 39 69 127 99 55 18 243 11 315 -12z"/>
                            <path d="M7004 2951 c-34 -10 -88 -30 -119 -46 -65 -31 -61 -39 25 -49 30 -3 114 -25 185 -48 111 -35 143 -42 218 -42 85 -1 90 0 113 27 13 15 24 33 24 39 0 24 -90 97 -144 117 -72 27 -212 28 -302 2z m293 -50 c62 -28 102 -66 85 -83 -20 -20 -122 -11 -248 21 -66 17 -149 34 -183 37 -43 5 -58 10 -48 16 77 43 305 48 394 9z"/>
                        </g>
                    </svg>
                    <section className="project-refine" id="refiningDesign">
                        <h1 className="project-heading">Prototyping</h1>
                        <h2 className="project-subheading">High-fidelity Prototype</h2>
                        <p className="project-des">After completing our wireframe, we presented it to the class, we
                            received feedback from the professor and our classmates. We then used this feedback to create
                            a high fidelity prototype, which allowed us to further refine and improve our project.</p>

                        <h2 className="project-subheading">Video walk through</h2>
                        <div className="video-walk">
                            <video controls muted>
                                <source src={vid} type="video/mp4"/>
                            </video>
                        </div>
                    </section>
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 1280.000000 150.000000" preserveAspectRatio="none">
                        <g transform="translate(0.000000,400.000000) scale(0.100000,-0.100000)" fill="#ceb8a4" stroke="none">
                            <path d="M4939 3411 c-113 -22 -229 -116 -282 -229 -30 -62 -32 -75 -32 -172 0 -92 3 -112 26 -160 36 -77 71 -115 137 -147 49 -24 69 -28 142 -28 107 0 164 21 218 79 67 73 79 158 36 245 -36 71 -86 104 -168 109 -49 3 -70 -1 -106 -20 -53 -27 -84 -74 -74 -113 l7 -26 28 34 c36 44 108 63 172 46 110 -30 152 -177 74 -265 -49 -57 -100 -78 -188 -78 -67 -1 -81 3 -125 29 -91 57 -141 181 -132 327 14 208 205 360 411 327 127 -21 230 -71 426 -208 207 -145 349 -220 531 -279 269 -88 452 -88 720 0 183 60 325 135 531 279 196 137 299 187 426 208 206 33 397 -119 411 -327 9 -146 -41 -270 -132 -327 -44 -26 -58 -30 -125 -29 -88 0 -139 21 -188 78 -78 88 -36 235 74 265 64 17 136 -2 172 -46 l28 -34 7 26 c10 39 -21 86 -74 113 -36 19 -57 23 -106 20 -82 -5 -132 -38 -168 -109 -43 -87 -31 -172 36 -245 54 -58 111 -79 218 -79 140 0 221 51 279 175 23 48 26 68 26 160 0 97 -2 110 -32 172 -40 86 -110 156 -194 196 -176 84 -376 33 -646 -165 -277 -203 -488 -305 -738 -358 -202 -42 -425 -2 -690 124 -135 65 -208 110 -378 234 -226 165 -403 228 -558 198z"/>
                            <path d="M4444 3070 c-49 -20 -66 -77 -39 -129 29 -56 125 -58 154 -3 42 79 -33 165 -115 132z m66 -48 c18 -14 21 -25 16 -45 -8 -35 -49 -46 -76 -22 -25 23 -25 40 0 65 25 25 32 25 60 2z"/>
                            <path d="M8262 3055 c-54 -45 -42 -124 23 -151 62 -26 125 20 125 90 0 36 -39 76 -81 82 -28 5 -41 1 -67 -21z m88 -35 c25 -25 25 -42 0 -65 -27 -24 -68 -13 -76 22 -5 20 -2 31 16 45 28 23 35 23 60 -2z"/>
                            <path d="M16 3017 c-24 -18 -17 -48 14 -64 20 -10 453 -12 2148 -10 2089 2 2124 2 2138 21 18 25 18 31 -2 50 -14 14 -223 16 -2148 16 -1747 0 -2135 -2 -2150 -13z"/>
                            <path d="M8486 3014 c-20 -19 -20 -25 -2 -50 14 -19 49 -19 2138 -21 2246 -3 2168 -4 2176 42 10 47 103 45 -2164 45 -1925 0 -2134 -2 -2148 -16z"/>
                            <path d="M5492 2948 c-52 -19 -142 -93 -142 -116 0 -6 11 -24 24 -39 23 -27 28 -28 113 -27 75 0 107 7 218 42 72 23 155 45 185 48 88 10 91 18 19 52 -137 65 -310 82 -417 40z m368 -43 l55 -18 -80 -12 c-44 -7 -127 -24 -185 -40 -111 -28 -213 -36 -232 -17 -22 22 39 69 127 99 55 18 243 11 315 -12z"/>
                            <path d="M7004 2951 c-34 -10 -88 -30 -119 -46 -65 -31 -61 -39 25 -49 30 -3 114 -25 185 -48 111 -35 143 -42 218 -42 85 -1 90 0 113 27 13 15 24 33 24 39 0 24 -90 97 -144 117 -72 27 -212 28 -302 2z m293 -50 c62 -28 102 -66 85 -83 -20 -20 -122 -11 -248 21 -66 17 -149 34 -183 37 -43 5 -58 10 -48 16 77 43 305 48 394 9z"/>
                        </g>
                    </svg>
                    <section className="project-next" id="goingForward">
                        <h1 className="project-heading">Going Forward</h1>

                        <h2 className="project-subheading">Takeaways</h2>
                        <h3>Impact</h3>
                        <p className="project-des">
                            Designing an interactive learning experience for middle school students about food
                            waste was a fulfilling project for us. We recognized the importance of addressing
                            this issue and wanted to create an engaging and meaningful way for young learners to
                            learn about it. While we were unable to present our project to students as this was
                            a class project, we were proud of the interactive experience we created and believed
                            it would effectively educate and motivate middle school students to take action and
                            make a positive impact in their own lives and communities.
                        </p>
                        <h3>What I learned</h3>
                        <p className="project-des">
                            Working in a group with individuals from different backgrounds and perspectives was
                            an interesting and rewarding experience. We had to listen to and consider everyone's
                            ideas and find a way to integrate them into our project. It was not always easy, but
                            we were able to compromise and come to a consensus that we were all happy with. In
                            the end, our hard work paid off and we were proud of the interactive learning experience
                            we had designed. It was a great feeling to see our final prototype and positive
                            feedback we received from the professor and the client.
                        </p>
                        <h2 className="project-subheading">Let’s connect!</h2>
                        <p className="project-des">If you have any feedback and constructive criticism,
                            please send me a message, email or connect via Linkedin; any and all feedback and criticism will be
                            highly appreciated. <br/><br/> Thank you for taking the time to read
                            the case study.</p>
                    </section>
                    <section>
                        <h1 className="project-heading">Other Projects</h1>
                        <div>
                            <div className="projects-min">
                                {projects.map(project => (
                                    <div className="project">
                                        <img className="banner-image project-deen" src={project.banner} key={project.title} alt=""/>
                                        <div className="project-info">
                                            <p className="project-title">{project.title}</p>
                                            <p className="project-brief">{project.description}</p>
                                            <a href={project.link}>
                                            <span className="project-link">
                                              <span className="button-text">{project.button}</span>
                                              {/*<span className="button-icon"><IoIosArrowRoundForward/></span>*/}
                                            </span>
                                            </a>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </main>
    );
});

export default InteractiveDesign;