import React from 'react';
import '../style/footer.css';
// import {AiFillLinkedin} from "react-icons/ai";
// import {FaGithubAlt} from "react-icons/fa";

function Footer(props) {
    return (
        <div className="footer">
            <span>&copy; <em id="date">2022</em> Farjana Chadni</span>
            <ul>
                <li>
                    <a href="https://www.linkedin.com/in/farjana-chadni" target="_blank" rel="noopener noreferrer">
                        <span className="social-i">Linkedin</span>
                    </a>
                </li>
                <li>
                    <a href="https://github.com/FChadni" target="_blank" rel="noreferrer">
                        <span className="social-i">Github</span>
                    </a>
                </li>
            </ul>
        </div>
    );
}

export default Footer;